import { graphql, useStaticQuery } from 'gatsby'

type TSection = {
  data: any
  name: string
}

export const useGlobalCmsComponentsQuery = () => {
  const data = useStaticQuery(
    graphql`
      query cmsGlobalQuery {
        cmsGlobal {
          sections {
            data
            name
          }
        }
      }
    `
  )

  const { sections } = data?.cmsGlobal

  const HAS_WINDOW =
    typeof window !== 'undefined' && typeof window !== undefined

  const storedCity = HAS_WINDOW
    ? JSON.parse(localStorage?.getItem('Location') ?? '{}')
    : '{}'

  const currentCityFromStorage: string | null = storedCity?.description || null

  const stickBarTexts = sections
    .filter((section: TSection) => section.name === 'StickBar')[0]
    ?.data?.allItems.filter((item: any) => {
      const { locations } = item

      return (
        !locations ||
        locations.length === 0 ||
        (locations.length === 1 && locations[0] === null) ||
        (locations.length === 1 && locations[0] === 'Seleccionar todos') ||
        (currentCityFromStorage && locations.includes(currentCityFromStorage))
      )
    })
    .map((item: any) => item.text)

  const collectionListData = sections.filter(
    (section: TSection) => section.name === 'CollectionList'
  )[0]?.data

  const footerLinks = sections.filter(
    (section: TSection) => section.name === 'FooterLinks'
  )[0]?.data

  return {
    stickBarTexts,
    collectionListData,
    footerLinks,
  }
}
