import Carousel from '@acctglobal/carousel-universal'
import type { SetStateAction } from 'react'
import { useEffect, useRef, Suspense } from 'react'
import { useInView } from 'react-intersection-observer'
import { useMediaQuery } from 'react-responsive'
import ProductShelfSkeleton from 'src/components/skeletons/ProductShelfSkeleton'
import { useViewItemListEvent } from 'src/sdk/analytics/hooks/useViewItemListEvent'
import { useProductsQuery } from 'src/sdk/product/useProductsQuery'
import { filterAvailableProducts } from 'src/utils/filterAvailableProducts'
import type { IStoreSelectedFacet } from '@generated/graphql'

import ProductCard from '../../product/ProductCard'
import Section from '../Section'

interface ProductShelfProps {
  Title?: string | JSX.Element
  Link?: string
  withDivisor?: boolean
  titleClassName?: string
  CollectionID?: string
  term?: string
  setHasProducts?: React.Dispatch<SetStateAction<boolean[]>>
  hasProducts?: boolean[]
  facets?: IStoreSelectedFacet[]
  productId?: string
  locations?: any
}

function ProductShelf({
  CollectionID: collectionID,
  Link: link,
  Title: title,
  withDivisor = false,
  titleClassName,
  term,
  setHasProducts,
  hasProducts,
  facets,
  productId,
  locations,
}: ProductShelfProps) {
  const HAS_WINDOW =
    typeof window !== 'undefined' && typeof window !== undefined

  const storedLocation = HAS_WINDOW
    ? JSON.parse(localStorage?.getItem('Location') ?? '{}')
    : '{}'

  const currentCityFromStorage = storedLocation?.description ?? null
  const isLocationsEmpty =
    !locations || // locations é null ou undefined
    (locations.length === 1 && locations[0] === null) // locations contém apenas null

  if (
    !isLocationsEmpty && // Não aplica a regra se locations estiver vazio ou null
    locations.length > 0 &&
    !locations.includes('Seleccionar todos') &&
    (!currentCityFromStorage || !locations.includes(currentCityFromStorage))
  ) {
    return null // Retorna null se as condições de exibição não forem atendidas
  }
  const selectedFacets = collectionID
    ? [{ key: 'productClusterIds', value: collectionID }]
    : [{ key: 'productClusterIds', value: '0' }]

  const variables = {
    selectedFacets: facets ?? selectedFacets,
    term,
  }

  const viewedOnce = useRef(false)
  const { ref, inView } = useInView()
  const { products, isValidating } = useProductsQuery(variables)
  const productEdges = products?.edges ?? []
  const isTitleString = typeof title === 'string'

  const filteredProducts = productEdges.filter((e) => e.node.id !== productId)

  const availableProducts =
    products && filteredProducts.length
      ? filterAvailableProducts(filteredProducts)
      : []

  const isMobile = useMediaQuery({
    query: '(max-width: 540px)',
  })

  const isTablet = useMediaQuery({
    query: '(max-width: 1279px)',
  })

  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 1280px)',
  })

  const { sendViewItemListEvent } = useViewItemListEvent({
    products: productEdges,
    title: isTitleString ? title : '',
    page: 0,
    pageSize: 0,
  })

  useEffect(() => {
    if (!hasProducts || !setHasProducts) {
      return
    }

    if (productEdges.length !== 0) {
      setHasProducts([...hasProducts, true])

      return
    }

    setHasProducts([...hasProducts, false])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productEdges])

  useEffect(() => {
    if (inView && !viewedOnce.current && productEdges.length) {
      sendViewItemListEvent()

      viewedOnce.current = true
    }
  }, [inView, productEdges.length, sendViewItemListEvent])

  const itemsQuantity = () =>
    Number(
      (isMobile && 2.99) || (isTablet && 4.99) || (isDesktopOrLaptop && 6.99)
    )

  const bulletProperties = {
    isVisible: true,
    bulletEnableColor: '#E5002B',
    bulletDisableColor: '#fff',
    style: {
      border: '1px solid #E5002B',
      padding: 0,
      borderRadius: 50,
      marginTop: 0,
      marginBottom: 0,
    },
  }

  if (availableProducts.length === 0 && !isValidating) {
    return null
  }

  return (
    <Suspense fallback={<ProductShelfSkeleton loading />}>
      <Section
        className={`layout__section ${
          withDivisor ? 'section__divisor' : ''
        } product-shelf__${isTitleString && title.split(' ')[0]}`}
      >
        <div className={`product-shelf__title ${titleClassName ?? ''}`}>
          <h2 className="text__title-section layout__content">{title}</h2>
          {link && link !== '/' && (
            <a
              className="navigation__more"
              href={link}
              aria-label="See all products"
            >
              Ver todas
            </a>
          )}
        </div>
        <div data-fs-product-shelf ref={ref}>
          <ProductShelfSkeleton
            loading={availableProducts.length === 0 || products === undefined}
          >
            <Carousel
              gapItems={isMobile ? 8 : 15}
              qtyItems={itemsQuantity()}
              arrow={
                isMobile
                  ? { isVisible: false }
                  : {
                      isVisible: true,
                      iconColor: 'black',
                      style: {
                        padding: 0,
                        margin: 0,
                        width: 24,
                        height: 24,
                      },
                    }
              }
              bullet={{
                ...bulletProperties,
                style: {
                  ...bulletProperties.style,
                  width: isMobile ? 6 : 12,
                  height: isMobile ? 6 : 12,
                },
              }}
            >
              {availableProducts?.map((product: any, index: number) => (
                <div style={{ height: 335 }} key={index}>
                  <div
                    className="layout__content product-shelf"
                    key={`${product.node.id}`}
                  >
                    <ProductCard product={product.node} index={index + 1} />
                  </div>
                </div>
              ))}
            </Carousel>
          </ProductShelfSkeleton>
        </div>
      </Section>
    </Suspense>
  )
}

export default ProductShelf
