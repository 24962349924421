import './carousel-banner.scss'
import Carousel from '../../ui/Carousel'
import DynamicImages from './DynamicImage'

interface ICarouselBanner {
  allItems: BannerImagesData[]
}

function CarouselBanner({ allItems }: ICarouselBanner) {
  const HAS_WINDOW =
    typeof window !== 'undefined' && typeof window !== undefined

  const storedLocation = HAS_WINDOW
    ? JSON.parse(localStorage?.getItem('Location') ?? '{}')
    : '{}'

  const currentCity = storedLocation?.description ?? null

  const filteredImages = (allItems ?? []).filter((image) => {
    const locations = image.locations ?? []
    console.log(locations, 'locations of carousel')

    if (
      locations.length === 0 ||
      locations.every((loc: any) => loc === null) ||
      (locations.length === 1 && locations[0] === 'Seleccionar todos')
    ) {
      return true
    }

    return locations.some((location: any) => location === currentCity)
  })

  if (filteredImages.length === 0) {
    return <p>Não há banners disponíveis para sua localização atual.</p>
  }

  return (
    <Carousel
      infiniteMode
      controls="complete"
      timeout={8000}
      transition={{
        duration: 600,
        property: 'transform',
      }}
      carouselId="banner-carousel"
      carouselName="Banner Carousel"
    >
      {filteredImages.map((image, idx) => (
        <>
          <div className="hidden-mobile-banner" key={`desktop-${idx}`}>
            {image?.sources[0]?.srcSet && (
              <DynamicImages
                valueLoading="eager"
                imageValues={{
                  href: image.sources[0].srcSet,
                  title: image.alt,
                  id: `desktop-home-banner-${idx}`,
                  link: image.href,
                }}
                imageWidth={{
                  desktop: 1903,
                  mobile: 375,
                }}
                imageHeight={{
                  desktop: 299,
                  mobile: 233,
                }}
              />
            )}
          </div>

          <div className="display-mobile-banner" key={`mobile-${idx}`}>
            {image?.sources[1]?.srcSet && (
              <DynamicImages
                valueLoading="eager"
                imageValues={{
                  href: image.sources[1].srcSet,
                  title: image.alt,
                  id: `mobile-home-banner-${idx}`,
                  link: image.href,
                }}
                imageWidth={{
                  desktop: 1903,
                  mobile: 375,
                }}
                imageHeight={{
                  desktop: 299,
                  mobile: 233,
                }}
              />
            )}
          </div>
        </>
      ))}
    </Carousel>
  )
}

export default CarouselBanner
